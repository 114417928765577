<template>
    <div class="f6 h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
            style="height: 100vh; overflow: hidden">
        <div class="h-100 h-100 flex flex-column">
            <div class="flex h-100">
                <contract-setting-left-menu></contract-setting-left-menu>
                <div class="h-100 flex-auto text-align-left">
                    <div class="flex flex-column h-100 w-100 pl1 pr4">
                        <MetaRightSideHeader>
                            <template #title>用户管理</template>R
                            <template #middle-right><el-button type="primary">添加用户</el-button></template>
                        </MetaRightSideHeader>
                        <div class="data-container shadow" style="flex: 1">
                            <MetaListTable
                                    :table-height="tableHeight"
                                    :table-data="userList"
                                    :show-checkbox="true"
                                    >
                                <template v-slot:header>
                                    <div class="action">
                                        <div class="selector-wrap">
                                            <MetaListSelector :list="userList"></MetaListSelector>
                                        </div>
                                        <div style="margin-right: 30px">
                                            <el-input v-model="filter" placeholder="查询" size="small" clearable @change="this.currentChange(1)"/>
                                        </div>
                                        <div class="pagination-area">
                                            <MetaListPagination
                                                    :current-count="count > pageSize*pageIndex ? pageSize : count-pageSize*(pageIndex-1)"
                                                    :size="pageSize"
                                                    :current-index="pageIndex"
                                                    :total-count="count"
                                                    @size-change="3"
                                                    @current-change="currentChange">
                                            </MetaListPagination>
                                        </div>
                                    </div>
                                </template>
                                <el-table-column
                                    label="姓名"
                                    fixed="right"
                                    show-overflow-tooltip
                                    prop="userName"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="姓名"
                                    fixed="right"
                                    show-overflow-tooltip
                                    prop="userName"
                                >
                                </el-table-column>
                                <el-table-column
                                        label="电话"
                                        fixed="right"
                                        show-overflow-tooltip
                                        prop="phone"
                                >
                                </el-table-column>
                                <el-table-column
                                        label="邮箱"
                                        fixed="right"
                                        show-overflow-tooltip
                                        prop="email"
                                >
                                </el-table-column>
                                <el-table-column
                                        label="角色"
                                        fixed="right"
                                        show-overflow-tooltip
                                >
                                </el-table-column>
                                <el-table-column
                                        label="加入团队时间"
                                        fixed="right"
                                        show-overflow-tooltip
                                >
                                    <template #default="scope">
                                        <span>
                                            {{transformTimeInfoForList(scope.row.mainTeamJoinTime,new Date())}}
                                        </span>
                                    </template>
                                </el-table-column>
                            </MetaListTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import ContractSettingLeftMenu from "../components/ContractSettingLeftMenu";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";
import MetaListSelector from "../components/list/MetaListSelector";
import MetaListTable from "../components/list/MetaListTable";
import MetaListPagination from "../components/list/MetaListPagination";
import {transformTimeInfoForList} from "../util";

import {
    getTeamUserByPage,
} from "../api/api";
export default {
    name: "UserManagement",
    components: {MetaRightSideHeader,ContractSettingLeftMenu,MetaListTable,MetaListSelector,MetaListPagination},
    data() {
        return {
            transformTimeInfoForList,
            userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
            teamInfo: JSON.parse(sessionStorage.getItem("teamInfo")),
            tableHeight: document.body.clientHeight - 150,
            userList:[],
            pageIndex: 1,
            pageSize: 15,
            count: 0,
            filter: '',
        }
    },
    mounted() {
        getTeamUserByPage(this.teamInfo.teamId,this.pageSize,this.pageIndex).then(res => {
            if (res.status == 200) {
                this.userList = res.data.data.data;
                this.count = res.data.data.count;
            }else {
                this.metaMessage['error']('获取团队成员失败');
            }
        }).catch(err => {
            console.log(err)
            this.metaMessage['error']("获取团队成员失败");
        })
    },
    methods:{
        currentChange(newValue) {
            this.pageIndex=newValue;
            this.userList.forEach((item)=>item.checked=false);
            getTeamUserByPage(this.teamInfo.teamId,this.pageSize,this.pageIndex,this.filter).then(res => {
                if (res.status == 200) {
                    this.userList = res.data.data.data;
                    this.count = res.data.data.count;
                }else {
                    this.metaMessage['error']('获取团队成员失败');
                }
            }).catch(err => {
                console.log(err)
                this.metaMessage['error']("获取团队成员失败");
            })
        },
    },
}
</script>
<style scoped>
@import url("../assets/css/concord.css");
.data-container {
    text-align: left;

    background: white;
}

.data-dialog {
    text-align: left;

}

.el-dialog__header {
    text-align: left;
}

.addData-btn{
    height: 32px;
    box-sizing: border-box;
    min-height: unset;
    line-height: 6px;
    margin-left: 5px;
}

.stage-index{
    padding-right: 5px;
    border-right: solid 1px #ddd;
    margin-right: 10px;
    height: 20px;
    line-height: 20px;

}
.selector-wrap{
    flex:1
}
</style>